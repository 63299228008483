






import Vue from 'vue'
export default Vue.component('payment', {

})
